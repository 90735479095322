/* eslint-disable vue/valid-v-slot */

<template>
    <div class="rma-history">
        <base-text-field
            v-model="search"
            v-debounce="searchFromFilter"
            class="mb-3"
            :append-icon="mdiMagnify"
            label="Filter RMA history.."
            single-line
            clearable
            dark
            hide-details
            @click:clear="clearFilter"
        ></base-text-field>
        <v-data-table
            :server-items-length="items && items[0] && items[0].itemCount"
            :loading="rmasLoading"
            :headers="headers"
            :options.sync="options"
            :items="items"
            data-cy="rma-history-table"
            :footer-props="optionsDepOnBrowser"
            dense
        >
            <template v-slot:group.header="{ remove, toggle, group, groupBy }">
                <td :key="group" colspan="9" class="text-start" @click="toggle">
                    <button
                        type="button"
                        class="ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
                    >
                        <span class="v-btn__content">
                            <v-icon aria-hidden="true">{{ mdiMinus }}</v-icon>
                        </span>
                    </button>
                    {{ groupByDisplay(groupBy) }}: {{ group }}
                    <button
                        type="button"
                        class="ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
                        @click="remove"
                    >
                        <span class="v-btn__content">
                            <v-icon aria-hidden="true">{{ mdiClose }}</v-icon>
                        </span>
                    </button>
                </td>
            </template>

            <template v-slot:item.problem="{ item, value }">
                {{ value }}
                <v-tooltip
                    v-if="
                        item.additionalProblems &&
                        item.additionalProblems.length
                    "
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <a v-on="on">
                            (+{{ item.additionalProblems.length }} items)
                        </a>
                    </template>
                    <span v-for="e in item.additionalProblems" :key="e"
                        >{{ e }}<br
                    /></span>
                </v-tooltip>
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{ formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.closedAt="{ item }">
                {{ formatDate(item.closedAt) }}
            </template>

            <template v-slot:item.unit.serial="{ item }">
                <span :data-cy="`rma-serial-${item.unit.serial}`">
                    {{ item.unit.serial }}
                </span>
            </template>

            <template v-slot:item.customerStatus="{ item }">
                <span :data-cy="`rma-status-${item.uid}`">
                    {{ item.customerStatus }}
                </span>
            </template>

            <template v-slot:item.rmaType="{ item }">
                {{ item.rmaType === 'GVS_ADVANCE_EXCHANGE' ? 'Yes' : 'No' }}
            </template>

            <template v-slot:item.uid="{ item }">
                <a
                    :data-cy="`link-open-rma-details-${item.uid}`"
                    @click="exposeRmaDetails(item.uid)"
                    >{{ item.uid }}</a
                >
            </template>

            <template v-slot:item.tracking.inbound.trackingNumber="{ item }">
                <a
                    :href="
                        item.tracking &&
                        item.tracking.inbound &&
                        item.tracking.inbound.trackingUrl
                    "
                    target="_blank"
                >
                    {{
                        item.tracking &&
                        item.tracking.inbound &&
                        item.tracking.inbound.trackingNumber
                    }}</a
                >
            </template>
            <template v-slot:item.tracking.outbound.trackingNumber="{ item }">
                <a
                    :href="
                        item.tracking &&
                        item.tracking.outbound &&
                        item.tracking.outbound.trackingUrl
                    "
                    target="_blank"
                >
                    {{
                        item.tracking &&
                        item.tracking.outbound &&
                        item.tracking.outbound.trackingNumber
                    }}</a
                >
            </template>
        </v-data-table>
        <service-details
            ref="serviceDetails"
            :rma-values="singleRmaDetails"
            width="900px"
            @reloadHistory="fetchRmas"
        />
    </div>
</template>

<script>
import { rmaHistoryByUserId, rmaDetailsByUid } from '@/api'
import { mdiMinus, mdiClose, mdiMagnify } from '@mdi/js'
import { formatDateShort } from '@/utils'
import ServiceDetails from './service_details'
export default {
    name: `RmaHistoryTable`,

    components: {
        ServiceDetails
    },

    // metaInfo: { title: `RMA History` },

    data() {
        return {
            items: [],
            mdiMagnify,
            mdiMinus,
            mdiClose,
            rmasLoading: false,
            search: ``,
            singleRmaDetails: {},
            options: {
                sortBy: [`createdAt`],
                sortDesc: [true]
            },
            serialDetailsDialog: false,
            headerBase: [
                {
                    text: `RMA`,
                    value: `uid`,
                    groupable: false,
                    sortable: true
                },
                {
                    text: 'Advance Exchange(?)',
                    value: 'rmaType',
                    groupable: false,
                    sortable: false
                },
                {
                    text: `Serial #`,
                    value: `unit.serial`,
                    groupable: false,
                    sortable: true
                },
                {
                    text: `Model`,
                    value: `unit.model`,
                    sortable: true
                },
                {
                    text: `Reported Failure`,
                    value: `primaryFault`,
                    sortable: true
                },

                {
                    text: `Reported Problem`,
                    value: `problem`,
                    sortable: true
                },

                {
                    text: `Status`,
                    value: `customerStatus`,
                    sortable: true
                },
                {
                    text: `Inbound Tracking#`,
                    value: `tracking.inbound.trackingNumber`,
                    groupable: false
                },
                {
                    text: `Outbound Tracking#`,
                    value: `tracking.outbound.trackingNumber`,
                    groupable: false
                },

                {
                    text: `Created Date`,
                    value: `createdAt`,
                    groupable: false,
                    sortable: true
                },
                {
                    text: `Closed Date`,
                    value: `closedAt`,
                    groupable: false,
                    sortable: true
                }
            ]
        }
    },

    computed: {
        headers() {
            const count = this.items.reduce((acc, curr) => {
                if (curr.username !== undefined) return acc + 1
                return acc
            }, 0)

            if (count > 0)
                return [
                    {
                        text: `User`,
                        value: `username`,
                        sortable: true
                    },
                    ...this.headerBase
                ]
            return this.headerBase
        },
        optionsDepOnBrowser() {
            const ua = navigator.userAgent
            const isIE = ua.indexOf(`MSIE `) > -1 || ua.indexOf(`Trident/`) > -1

            return isIE
                ? {
                      disableItemsPerPage: true,
                      itemsPerPageText: ``,
                      itemsPerPageOptions: [5, 10, 30]
                  }
                : {
                      disableItemsPerPage: false,
                      itemsPerPageText: `Rows Per Page`,
                      itemsPerPageOptions: [5, 10, 30]
                  }
        }
    },
    watch: {
        options: {
            handler() {
                this.fetchRmas()
            },
            deep: true
        }
    },
    mounted() {
        this.fetchRmas()
    },
    methods: {
        clearFilter() {
            this.search = ``
            this.searchFromFilter()
        },
        searchFromFilter() {
            this.options.page = 1
            this.fetchRmas()
        },
        formatDate(v) {
            if (!v) return `--`
            else return formatDateShort(new Date(v))
        },

        async fetchRmas() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            this.rmasLoading = true
            const { data } = await rmaHistoryByUserId({
                sortBy,
                sortDesc,
                page,
                itemsPerPage,
                filterText: (`` + this.search).trim()
            })
            this.items = data ? data.rmas : []
            this.rmasLoading = false
        },

        async exposeRmaDetails(uid) {
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options
            const { data } = await rmaDetailsByUid({ uid }).catch((e) => ({
                data: {}
            }))
            this.$refs.serviceDetails.isOpen = true
            this.singleRmaDetails = data
        },
        groupByDisplay(groupBy = []) {
            return groupBy
                .map((group) => {
                    switch (group) {
                        case `problem`:
                            return `Reported Problem`
                        case `unit.model`:
                            return `Model`
                        case `primaryFault`:
                            return `Primary Fault`
                        case `latestStatus`:
                            return `Status`
                        default:
                            return group
                    }
                })
                .toString()
        }
    }
}
</script>
<style lang="scss">
.rma-history {
    a {
        text-decoration: none;
    }
    .v-data-table th[role='columnheader'] {
        white-space: nowrap;
    }
}
</style>
