<template>
    <div>
        <v-row justify="center">
            <v-dialog
                :key="rmaValues.uid"
                v-model="isOpen"
                v-bind="$attrs"
                :fullscreen="$vuetify.breakpoint.smAndDown"
            >
                <v-card>
                    <v-toolbar dark color="accent">
                        <v-toolbar-title>RMA Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="isOpen = false">
                            <v-icon>{{ mdiClose }}</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div v-if="rmaValues.uid">
                        <v-card-title>
                            <span
                                class="headline"
                                data-cy="rma-details-rma-number"
                            >
                                RMA# {{ rmaValues.uid }}
                                <span
                                    v-if="
                                        rmaValues.rmaType ===
                                        'GVS_ADVANCE_EXCHANGE'
                                    "
                                    >(Advance Exchange)</span
                                >
                            </span>
                            <v-spacer />

                            <base-btn
                                v-if="
                                    rmaValues.flags.allowFedexGroundPickup &&
                                    rmaValues.flags.allowCancel
                                "
                                class="mr-2"
                                color="blue darken-1"
                                outlined
                                @click="scheduleGroundReturnPickup"
                            >
                                FedEx Ground Pickup
                            </base-btn>

                            <base-btn
                                v-if="rmaValues.flags.allowRepairSummary"
                                class="mr-2"
                                color="blue darken-1"
                                outlined
                                @click="repairSummary"
                            >
                                Repair Summary
                            </base-btn>

                            <base-btn
                                color="green darken-1"
                                outlined
                                @click=";(isOpen = false), (sendMessage = true)"
                            >
                                need help?
                            </base-btn>
                        </v-card-title>

                        <div
                            v-if="
                                rmaValues.flags.allowFedexGroundPickup &&
                                rmaValues.flags.allowCancel
                            "
                            class="caption px-3 mx-3"
                        >
                            * It may take up to several hours for FedEx to
                            recognize a new Ground Return tracking number to
                            schedule a pick-up.</div
                        >

                        <v-alert
                            v-if="summaryError"
                            type="error"
                            outlined
                            class="mx-4"
                        >
                            Error Generating Repair Summary Report
                        </v-alert>

                        <base-card-summary
                            tile
                            flat
                            :data-map="summaryMap"
                            :all-data="rmaValues"
                        >
                            <template v-slot:problem="{ item, root }">
                                {{
                                    [
                                        item,
                                        ...(root.additionalProblems || [])
                                    ].join(', ')
                                }}
                            </template>
                            <template v-slot:customerStatus="{ item }">
                                <span data-cy="rma-details-latest-status">
                                    {{ item }}</span
                                >
                            </template>
                            <template v-slot:stage.preQuote.quoteId="{ item }">
                                {{ item }}
                                <br />
                                <service-details-quote :quote-id="item" />
                            </template>
                            <template v-slot:createdAt="{ item }">
                                {{ formatDate(item) }}
                            </template>
                            <template v-slot:closedAt="{ item }">
                                {{ formatDate(item) }}
                            </template>
                            <template v-slot:unit.endDeviceWarranty="{ item }">
                                {{ formatDate(item) }}
                            </template>
                            <template
                                v-slot:tracking.inbound.labelUrl="{ item }"
                            >
                                <a :href="item" target="_blank">
                                    Download PDF
                                </a>
                            </template>
                            <template
                                v-slot:stage.shipAdvanceExchange.parts.0.partSerial="{
                                    item,
                                    root
                                }"
                            >
                                <a
                                    :href="root.tracking.outbound.trackingUrl"
                                    target="_blank"
                                >
                                    {{ item }}
                                </a>
                            </template>
                            <template
                                v-slot:tracking.inbound.trackingNumber="{
                                    item,
                                    root
                                }"
                            >
                                <a
                                    :href="root.tracking.inbound.trackingUrl"
                                    target="_blank"
                                >
                                    {{ item }}
                                </a>
                            </template>
                            <template
                                v-if="
                                    rmaValues.tracking &&
                                    rmaValues.tracking.outbound &&
                                    rmaValues.tracking.outbound.trackingUrl
                                "
                                v-slot:tracking.outbound.trackingNumber="{
                                    item,
                                    root
                                }"
                            >
                                <a
                                    :href="
                                        root.tracking.outbound &&
                                        root.tracking.outbound.trackingUrl &&
                                        root.tracking.outbound.trackingUrl
                                    "
                                    target="_blank"
                                >
                                    {{ item }}
                                </a>
                            </template>
                        </base-card-summary>

                        <v-card-actions
                            :class="
                                $vuetify.breakpoint.smAndDown &&
                                'd-flex flex-column'
                            "
                        >
                            <base-btn
                                v-if="rmaValues.flags.allowCancel"
                                data-cy="btn-cancel-rma"
                                color="red"
                                :loading="cancellingRma"
                                :block="$vuetify.breakpoint.smAndDown"
                                outlined
                                :class="$vuetify.breakpoint.smAndDown && 'mb-2'"
                                @click="cancelRma"
                                >Cancel RMA
                            </base-btn>
                            <v-alert
                                v-if="cancelError"
                                block
                                type="error"
                                :class="
                                    $vuetify.breakpoint.smAndDown
                                        ? 'mb-2'
                                        : 'ml-2'
                                "
                            >
                                Error Canceling RMA
                            </v-alert>

                            <v-spacer></v-spacer>
                            <base-btn
                                :block="$vuetify.breakpoint.smAndDown"
                                outlined
                                @click="isOpen = false"
                                >Close Details
                            </base-btn>
                        </v-card-actions>
                    </div>
                    <div v-else>
                        <v-card-text>
                            No RMA details have been provided from our repair
                            center. If this is an error, please send us a
                            message.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                outlined
                                @click=";(isOpen = false), (sendMessage = true)"
                            >
                                Send a Message
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog
            v-model="sendMessage"
            width="600"
            :fullscreen="$vuetify.breakpoint.smAndDown"
        >
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>Create Case</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sendMessage = false">
                        <v-icon>{{ mdiClose }}</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <sf-form
                        :key="(rmaValues && rmaValues.uid) + caseKeyState"
                        outlined
                        dense
                        :serial-number="
                            rmaValues && rmaValues.unit && rmaValues.unit.serial
                        "
                        :model-name="
                            rmaValues && rmaValues.unit && rmaValues.unit.model
                        "
                        align-button="right"
                        :rma-number="rmaValues && rmaValues.uid"
                        private-form
                        @change="caseKeyState++"
                        @complete="sendMessage = false"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { userCancelRma, repairSummary } from '@/api'
import { formatDateShort } from '@/utils'
import sfForm from '@/components/support/sf_form'
import ServiceDetailsQuote from './quote_details'

export default {
    name: `ServiceDetails`,
    components: {
        sfForm,
        ServiceDetailsQuote
    },
    props: {
        rmaValues: {
            type: Object,
            default() {
                return {
                    uid: ``,
                    unit: {
                        serial: ``
                    }
                }
            }
        }
    },
    data() {
        return {
            cancelError: false,
            cancellingRma: false,
            summaryError: false,
            mdiClose,
            isOpen: false,
            sendMessage: false,
            caseKeyState: 0
        }
    },
    computed: {
        requiredQuote() {
            return (
                this.rmaValues &&
                this.rmaValues.stage &&
                this.rmaValues.stage.preQuote &&
                this.rmaValues.stage.preQuote.quoteId
            )
        },
        summaryMap() {
            return [
                {
                    mainTitle: ``,
                    mainItems: [
                        {
                            subTitle: ``,
                            subItems: [
                                {
                                    title: `Latest Status`,
                                    path: `customerStatus`
                                },
                                {
                                    title: `RMA Created Date`,
                                    path: `createdAt`
                                },
                                ...this.insertSummaryMapIf(
                                    this.rmaValues.closedAt,
                                    {
                                        title: `RMA Closed Date`,
                                        path: `closedAt`
                                    }
                                )
                            ]
                        },
                        ...this.insertSummaryMapIf(
                            this.isAdvanceExchange &&
                                this.rmaValues.stage.shipAdvanceExchange,
                            {
                                subTitle: 'Replacement Device',
                                subItems: [
                                    {
                                        title: 'Serial Number',
                                        path: 'stage.shipAdvanceExchange.parts.0.partSerial'
                                    },
                                    {
                                        title: 'Part Number',
                                        path: 'stage.shipAdvanceExchange.parts.0.partNumber'
                                    }
                                ]
                            }
                        ),
                        ...this.insertSummaryMapIf(
                            this.rmaValues.tracking.inbound ||
                                this.rmaValues.tracking.outbound,
                            {
                                subTitle: `Tracking & Shipping`,
                                subItems: [
                                    ...this.insertSummaryMapIf(
                                        this.rmaValues.tracking.inbound &&
                                            this.rmaValues.tracking.inbound
                                                .labelUrl,
                                        {
                                            title: `Shipping Label`,
                                            path: `tracking.inbound.labelUrl`
                                        }
                                    ),
                                    ...this.insertSummaryMapIf(
                                        this.rmaValues.tracking.inbound,
                                        {
                                            title: `Inbound Tracking #`,
                                            path: `tracking.inbound.trackingNumber`
                                        }
                                    ),

                                    ...this.insertSummaryMapIf(
                                        this.rmaValues.tracking.outbound,
                                        {
                                            title: `Outbound Tracking #`,
                                            path: `tracking.outbound.trackingNumber`
                                        }
                                    )
                                ]
                            }
                        ),
                        ...this.insertSummaryMapIf(this.requiredQuote, {
                            subTitle: `Paid Quote Details`,
                            subItems: [
                                {
                                    title: 'Quote ID',
                                    path: 'stage.preQuote.quoteId'
                                }
                            ]
                        }),
                        {
                            subTitle: `Reported RMA User Data`,
                            subItems: [
                                {
                                    title: `Primary Fault`,
                                    path: `primaryFault`
                                },
                                {
                                    title: `Problem`,
                                    path: `problem`
                                },
                                {
                                    title: `User Notes`,
                                    path: `initialNotes`
                                },
                                {
                                    title: `User including Hard Case`,
                                    path: `acc.hardCase`
                                },
                                {
                                    title: `User including AC Power`,
                                    path: `acc.acPower`
                                },
                                {
                                    title: `User including battery`,
                                    path: `acc.battery`
                                },
                                {
                                    title: `User including storage (HDD/SSD)`,
                                    path: `acc.storage`
                                },
                                {
                                    title: `Windows System Re-Image Approval`,
                                    path: `permissions.reimage`
                                },
                                {
                                    title: `BIOS Upgrade Approval`,
                                    path: `permissions.biosFirmware`
                                },
                                {
                                    title: `Windows User Name`,
                                    path: `unit.windowsUserName`
                                },
                                {
                                    title: `Unit Ref ID`,
                                    path: `custReference`
                                },
                                {
                                    title: `Asset Tag`,
                                    path: `assetTag`
                                }
                            ]
                        },
                        {
                            subTitle: `Unit Details`,
                            subItems: [
                                {
                                    title: `Serial #`,
                                    path: `unit.serial`
                                },
                                {
                                    title: `Model`,
                                    path: `unit.model`
                                },
                                {
                                    title: `Warranty Expiration Date`,
                                    path: `unit.endDeviceWarranty`
                                },
                                {
                                    title: `Warranty Type`,
                                    path: `unit.warrantyType`
                                }
                            ]
                        }
                    ]
                },
                {
                    mainTitle: `Contact Info`,
                    mainItems: [
                        {
                            subTitle: `Billing Info`,
                            subItems: [
                                {
                                    title: `First Name`,
                                    path: `customer.billing.contact.firstName`
                                },
                                {
                                    title: `Email`,
                                    path: `customer.billing.contact.email`
                                },
                                {
                                    title: `Phone`,
                                    path: `customer.billing.contact.phone`
                                },
                                {
                                    title: `Organization`,
                                    path: `customer.billing.contact.company`
                                },
                                {
                                    title: `Position`,
                                    path: `customer.billing.contact.position`
                                },
                                {
                                    title: ``,
                                    path: ``
                                },
                                {
                                    title: `Address 1`,
                                    path: `customer.billing.address.line1`
                                },
                                {
                                    title: `Address 2`,
                                    path: `customer.billing.address.line2`
                                },
                                {
                                    title: `City`,
                                    path: `customer.billing.address.city`
                                },
                                {
                                    title: `State`,
                                    path: `customer.billing.address.state`
                                },
                                {
                                    title: `Country`,
                                    path: `customer.billing.address.country`
                                },
                                {
                                    title: `Zip/Postal Code`,
                                    path: `customer.billing.address.postalCode`
                                }
                            ]
                        },
                        {
                            subTitle: `Shipping Info`,
                            subItems: [
                                {
                                    title: `First Name`,
                                    path: `customer.shipping.contact.firstName`
                                },
                                {
                                    title: `Last Name`,
                                    path: `customer.shipping.contact.lastName`
                                },
                                {
                                    title: `Email`,
                                    path: `customer.shipping.contact.email`
                                },
                                {
                                    title: `Phone`,
                                    path: `customer.shipping.contact.phone`
                                },
                                {
                                    title: `Organization`,
                                    path: `customer.shipping.contact.company`
                                },
                                {
                                    title: `Position`,
                                    path: `customer.shipping.contact.position`
                                },
                                {
                                    title: ``,
                                    path: ``
                                },
                                {
                                    title: `Address 1`,
                                    path: `customer.shipping.address.line1`
                                },
                                {
                                    title: `Address 2`,
                                    path: `customer.shipping.address.line2`
                                },
                                {
                                    title: `City`,
                                    path: `customer.shipping.address.city`
                                },
                                {
                                    title: `State`,
                                    path: `customer.shipping.address.state`
                                },
                                {
                                    title: `Country`,
                                    path: `customer.shipping.address.country`
                                },
                                {
                                    title: `Zip/Postal Code`,
                                    path: `customer.shipping.address.postalCode`
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        isAdvanceExchange() {
            return this.rmaValues.rmaType === 'GVS_ADVANCE_EXCHANGE'
        }
    },
    methods: {
        insertSummaryMapIf(condition, obj) {
            return condition ? [obj] : []
        },
        formatDate(v) {
            if (!v) return `--`
            else return formatDateShort(new Date(v))
        },
        scheduleGroundReturnPickup() {
            let url = `https://www.fedex.com/grd/rpp/ShowRPP.do?trackingId=${this.rmaValues.tracking.inbound.trackingNumber}`

            url += `&contactName=${this.rmaValues.customer.shipping.contact.firstName}%20${this.rmaValues.customer.shipping.contact.lastName}`
            url += `&address1=${this.rmaValues.customer.shipping.address.line1}`
            url += `&city=${this.rmaValues.customer.shipping.address.city}`
            url += `&state=${this.rmaValues.customer.shipping.address.state}`
            url += `&zip=${this.rmaValues.customer.shipping.address.postalCode}`
            url += `&phoneNum=${this.rmaValues.customer.shipping.contact.phone}`
            url += `&numPackages=1`

            if (this.rmaValues.customer.shipping.address.line2)
                url += `&address2=${this.rmaValues.customer.shipping.address.line2}`
            if (this.rmaValues.customer.shipping.contact.company)
                url += `&companyName=${this.rmaValues.customer.shipping.contact.company}`

            const win = window.open(url, `_blank`)
            win.focus()
        },
        async repairSummary() {
            const self = this
            if (!this.repairSummary) {
                console.error('not able to request repair summary at this time')
            }
            await repairSummary({ rmaNumber: this.rmaValues.uid })
                .then(({ data: b64 }) => {
                    const dataURItoBlob = (dataURI) => {
                        const byteString = atob(dataURI.split(`,`)[1])
                        const mimeString = dataURI
                            .split(`,`)[0]
                            .split(`:`)[1]
                            .split(`;`)[0]

                        // write the bytes of the string to an ArrayBuffer
                        const ab = new ArrayBuffer(byteString.length)
                        const ia = new Uint8Array(ab)
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i)
                        }

                        // write the ArrayBuffer to a blob, and you're done
                        const bb = new Blob([ab], { type: mimeString })
                        return bb
                    }
                    const linkSource = `data:application/pdf;base64,${b64}`
                    const pdfBlob = dataURItoBlob(linkSource)

                    /**
                     * Internet Explorer stuff!
                     */
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(
                            pdfBlob,
                            `${self.rmaValues.uid}-repair-summary.pdf`
                        )
                        return
                    }

                    const url = window.URL.createObjectURL(pdfBlob)
                    const link = document.createElement(`a`)
                    link.href = url
                    link.setAttribute(
                        `download`,
                        `${self.rmaValues.uid}-repair-summary.pdf`
                    )
                    document.body.appendChild(link)
                    link.click()

                    link.remove()
                })
                .catch(() => {
                    this.summaryError = true
                })
                .finally(() => {
                    setTimeout(function () {
                        self.summaryError = false
                    }, 5000)
                })
        },
        async cancelRma() {
            if (
                confirm(
                    `Are you sure you with to cancel RMA# ${this.rmaValues.uid}`
                )
            )
                try {
                    this.cancellingRma = true
                    if (!this.rmaValues.closedAt && this.rmaValues.uid) {
                        await userCancelRma({ rmaNumber: this.rmaValues.uid })
                        this.$emit(`reloadHistory`)
                    }

                    this.isOpen = false
                } catch {
                    this.cancelError = true
                } finally {
                    this.cancellingRma = false
                    const self = this
                    setTimeout(function () {
                        self.cancelError = false
                    }, 5000)
                }
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
